import React from "react"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"

const ContactThanksPage = () => {

    return (
        <Layout>
            <SEO
                title="Contacto"
                description="Agencia de Marketing Online especializada en la optimización en buscadores, posicionamiento web SEO, publicidad online SEM, analítica web, gestión de redes sociales para empresas, campañas de e-mailing y diseño de páginas web e e-commerce."
            />

            <BannerPage
                title="Gracias por contactar con IBX"
                text="Con ibx, tu presencia online está asegurada. Contacta con nosotros y te haremos el plan que necesitas para que tu empresa triunfe en Internet"
                color="green"
            />

        </Layout>
    )

}
export default ContactThanksPage
